import { isVanfCleefProcess } from "helpers/constants";
import { JewelDto, JewelSourceTypeLabelDto, JewelStatusLabel, JewelStatusLabelDto } from "models/Dto/JewelsDto.interface";
import { StoneDto } from "models/Dto/StonesDto.interface";

export const isJewelStatusSold = (status: JewelStatusLabelDto): boolean =>
  status === JewelStatusLabelDto.SOLD;

export const isJewelStatusNotSold = (status: JewelStatusLabelDto): boolean =>
  status === JewelStatusLabelDto.NOTSOLD;

export const isJewelStatusForSale = (status: JewelStatusLabelDto): boolean =>
  status === JewelStatusLabelDto.FORSALE;

export const isMarketplace = (sourceType: JewelSourceTypeLabelDto): boolean =>
  sourceType === JewelSourceTypeLabelDto.MARKETPLACE;

export const getStatusLabel = ({status, source_type}: JewelDto): string => {
  if(isVanfCleefProcess() && isMarketplace(source_type) && isJewelStatusNotSold(status)) {
    return JewelStatusLabelDto.WITHDRAWN
  } else if (status?.toLowerCase() === JewelStatusLabelDto.SOLD.toLowerCase()) {
    return JewelStatusLabel.SOLD;
  } else if (status?.toLowerCase() === JewelStatusLabelDto.NOTSOLD.toLowerCase()) {
    return JewelStatusLabel.NOTSOLD
  } else if (status?.toLowerCase() === JewelStatusLabelDto.FORSALE.toLowerCase()) {
    return JewelStatusLabel.FORSALE
  } else if (status?.toLowerCase() === JewelStatusLabelDto.WITHDRAWN.toLowerCase()) {
    return JewelStatusLabel.WITHDRAWN
  }
  return status
}

export const getCaratNumber1 = (stones: StoneDto[]):number => stones.find(stone => stone.number === 1)?.carat?.total